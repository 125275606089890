<template>
  <v-content>
    <cookie-banner />
    <router-view />
    <dashboard-core-footer v-if="!mobileDevice" />
    <mobile-footer v-if="mobileDevice" />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
      MobileFooter: () => import('./MobileFooter'),
      CookieBanner: () => import('./CookieBanner'),
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
    }
  }
</script>
